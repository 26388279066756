<template>
    <div class="h-full flex flex-col pb-0">
       <div class="flex flex-1 justify-between overflow-auto">
          <div class="w-full  px-4 text-black">
                <icon name="annotation" class="w-32 h-32 m-auto mt-2" />
                <p class="p-10 text-xl">
                    This side of the screen would leverage the Chat service that is already in use by the Service Request 
                    service.  This is an opportunity for the resident(s) and the property staff to discuss, negotiate, 
                    or otherwise communicate about the renewal offer before the resident decides to accept or reject the offer.
                    The Chat service is available via API.
                </p>
                <Messenger :chat-id="chatId"/>
          </div>
         </div>

    </div>
</template>
<script>
import AuthMixin from '@/components/auth/AuthMixin'
import Icon from '@/components/ui/Icon'
import ModalNavigation from '@/mixins/ModalNavigation'
import Messenger from "@/components/sreq/feed/Messenger";
import {MESSAGE_TYPE_SIMPLE} from "@/utils/constants/chat";

export default {
    name: 'EditRenewalOffer',
    components:{
      Icon, 
      Messenger
    },
    mixins:[AuthMixin, ModalNavigation],
    data() {
        return {
            content: "<p>Some initial content</p>",
            reasonsForNo: [],
            footer: {
                secondaryButton: 'cancel',
            },
            chatId: "0cc0fdc0-5b0a-4187-9046-5df4d8dfa561",
            initialFilter: MESSAGE_TYPE_SIMPLE,
            currentTab: 1
        }
    },
 
}
</script>

<style scoped>
  .active {
    color: var(--highlightColor500);
    border-bottom: 2px solid;
    border-color: var(--highlightColor500);
    margin: 0 5px 0 5px;
  }
  .activeCatg {
    background-color: var(--highlightColor500);
    @apply text-white border-transparent;
  }
</style>